export const k36Langs = [ 'en-US', 'th-TH', 'vi-VN', 'zh-CN', ];
export const allLangs = [ 'th-TH','vi-VN','zh-CN','en-US','hi-IN','id-ID','ja-JP','ml-IN','ms-MY','my-MM','ta-IN','zh-TW','pt-BR', 'es-MX', 'km-KH', 'tl-PH', 'ko-KR', 'bn-BD' ];
export const allCurrency = ['THB','VND2','INR','IDR2','IDR','MYR','CNY','PTS','PHP','USD','HKD','BRL','MMK','USDT', 'MXN', 'JPY', 'BDT', 'EUR', 'KRW', 'LAK2', 'PKR', 'PGK', 'AUD'];
export const allTelCountry = ['my','th','vn','us','in','id','jp','hk','sg', 'cn', 'pt', 'br', 'ph', 'pk', 'mm', 'au', 'mx', 'bd', 'kh', 'it', 'tz', 'kr', 'la', 'pg'];
//後台語言對應語系
export const cmsLang = {
    'default': ['zh-CN','en-US'],
    'VND2':    ['zh-CN','en-US','vi-VN'],
    'THB':     ['zh-CN','en-US','th-TH'],
    'IDR2':    ['zh-CN','en-US','id-ID'],
    'IDR':     ['zh-CN','en-US','id-ID']
};
export const localeToName = {
    'en-US': 'English',
    'zh-CN': '简体中文',
    'zh-TW': '繁體中文',
    'vi-VN': 'Tiếng Việt',
    'th-TH': 'ไทย',
    'id-ID': 'Indonesia',
    'ms-MY': 'Malay',
    'ja-JP': '日本語',
    'hi-IN': 'हिंदी',
    'ta-IN': 'தமிழ்',
    'ml-IN': 'മലയാളം',
    'my-MM': 'မြန်မာ',
    'pt-BR': 'Português',
    'es-MX': 'Español',
    'km-KH': 'ខ្មែរ',
    'ko-KR': '한국어',
    'it-IT': 'Italiano',
    'tl-PH': 'Tagalog',
    'bn-BD': 'Bangla',
};
export const CurrencyToName = {
    'THB' : 'THB',
    'VND2' : 'VND(k)',
    'INR' : 'INR',
    'IDR2' : 'IDR(k)',
    'IDR' : 'IDR',
    'MYR' : 'MYR',
    'CNY' : 'CNY',
    'PTS' : 'PTS',
    'PHP' : 'PHP',
    'USD' : 'USD',
    'HKD' : 'HKD',
    'BRL' : 'BRL',
    'MMK' : 'MMK',
    'USDT' : 'USDT',
    'MXN' : 'MXN',
    'JPY' : 'JPY',
    'BDT' : 'BDT',
    'EUR' : 'EUR',
    'KRW' : 'KRW',
    'LAK2' : 'LAK',
    'PKR' : 'PKR',
    'PGK' : 'PGK',
    'AUD' : 'AUD',
};
